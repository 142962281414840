import {
  ActivityTeaserFragmentFragment,
  TermCategory,
} from '../../../generated/types';
import Card from '../../Common/Card';
import Location from '../../Common/Location';
import React from 'react';
import ActivityCategoryBadge from '../../Common/ActivityCategoryBadge';
import { TeaserProps } from '../../../types/global-types';
import getAppConfig from '../../../utils/getAppConfig';

type ActivityTeaserProps = TeaserProps & {
  data: ActivityTeaserFragmentFragment;
};

const ActivityTeaser: React.FC<ActivityTeaserProps> = ({
  data,
  showCategory,
  ...rest
}) => {
  const appConfig = getAppConfig();
  const isInfolino = appConfig.config.frontendId === 'infolino';
  const url = isInfolino
    ? `https://www.freizeithotels.info${data.url}`
    : data.url;

  return (
    <Card
      key={data.id}
      href={url}
      image={
        data.teaserImage &&
        data.teaserImage.teaser &&
        data.teaserImage!.teaser!.url
      }
      preTitle={
        <ActivityCategoryBadge category={data.category as TermCategory} />
      }
      title={data.title}
      text={data.body || ''}
      meta={
        <Location country={data.country?.name} region={data.region?.name} />
      }
      target={isInfolino ? '_blank' : '_self'}
      {...rest}
    />
  );
};

export default ActivityTeaser;
