import { Badge, Flex } from '@chakra-ui/react';
import React from 'react';
import { TermCategory } from '../../generated/types';
import getIconColorForCategoryKey from '../../utils/getIconColorForCategoryKey';
import getIconForCategoryKey from '../../utils/getIconForCategoryKey';

interface Props {
    category: TermCategory;
}

const ActivityCategoryBadge: React.FC<Props> = ({ category }) => {
    if (!category) return null;

    const Icon = getIconForCategoryKey(category.key!);
    const iconColor = getIconColorForCategoryKey(category.key!);

    return (
        <Badge bg={iconColor} color="#fff">
            <Flex alignItems="center">
                {Icon && <Icon marginRight={1} />}
                {category.name}
            </Flex>
        </Badge>
    );
};

export default ActivityCategoryBadge;
