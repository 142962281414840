import { AspectRatio, Badge, Box, BoxProps, Flex, Img } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import LazyLoad from 'react-lazyload';
import { useStore } from '../../store/provider';
import truncateText from '../../utils/truncate-text';

export type CardProps = {
  image?: string | null;
  label?: string;
  title: string;
  text: string;
  meta?: string | JSX.Element;
  href?: string;
  small?: boolean;
  textLength?: number;
  noHeight?: boolean;
  noMeta?: boolean;
  leftImage?: boolean | null;
  imageAlt?: string | null;
  imageTitle?: string | null;
  imageCopyright?: string | null;
  disableLazy?: boolean;
  preTitle?: string | JSX.Element | null;
  preBody?: string | JSX.Element | null;
  afterBody?: string | JSX.Element | null;
  onImageLoaded?: () => void;
  target?: string;
} & BoxProps;

const Card: React.FC<CardProps> = ({
  image,
  label,
  title,
  text,
  meta,
  href,
  small,
  textLength,
  noHeight,
  noMeta,
  leftImage,
  imageAlt,
  imageTitle,
  imageCopyright,
  onImageLoaded,
  disableLazy,
  preTitle,
  preBody,
  afterBody,
  target,
  ...props
}) => {
  const isLink = !!href;
  const height = small || noHeight ? '0' : '225px';
  const store = useStore();
  const fallbackImage =
    store.backendConfig.defaultActivityImage &&
    store.backendConfig.defaultActivityImage.teaser.url;

  const Image = (
    <AspectRatio ratio={424 / 225}>
      <Img
        alt={imageAlt || ''}
        title={imageTitle || ''}
        borderTopLeftRadius="md"
        borderTopRightRadius="md"
        width={leftImage ? '100px' : '100%'}
        src={image || fallbackImage!}
        onLoad={onImageLoaded}
      />
    </AspectRatio>
  );

  const CardInner = (
    <Box
      className="card-box"
      borderRadius="md"
      border="1px"
      borderColor="gray.200"
      bg="#fff"
      maxWidth={small ? '200px' : undefined}
      _hover={
        isLink
          ? {
              shadow: 'lg',
            }
          : {}
      }
      {...props}
    >
      <Flex direction={leftImage ? 'row' : 'column'}>
        <Box pos="relative">
          {(image || fallbackImage) &&
            (disableLazy ? (
              Image
            ) : (
              <LazyLoad height={height}>{Image}</LazyLoad>
            ))}
          {!image && !fallbackImage && (
            <Box
              height={height}
              borderTopLeftRadius="md"
              borderTopRightRadius="md"
              bgColor="gray.200"
            />
          )}

          {label && (
            <Badge
              bg="brand.600"
              color="#fff"
              fontSize="md"
              pos="absolute"
              top="0"
              right="0"
              margin={4}
              paddingX={2}
              textTransform="none"
            >
              {label}
            </Badge>
          )}
        </Box>

        <Box paddingX={small ? 2 : 4} paddingBottom={small ? 2 : 4}>
          {preTitle && <Box marginTop={small ? 2 : 4}>{preTitle}</Box>}
          <Box fontWeight="bold" paddingTop={small ? 2 : preTitle ? 2 : 4}>
            {title}
          </Box>
          {preBody && (
            <Box marginTop={1} marginBottom={small ? 2 : 4}>
              {preBody}
            </Box>
          )}
          <Box color="gray.600" fontSize={small ? 'xs' : 'md'} marginTop={2}>
            {truncateText(text, textLength || 150)}
          </Box>
          {afterBody && <Box marginTop={small ? 2 : 4}>{afterBody}</Box>}
        </Box>
      </Flex>
      {!noMeta && meta && (
        <Box
          fontSize="sm"
          borderTop="1px"
          borderTopColor="gray.100"
          paddingX={4}
          paddingY={2}
        >
          {meta}
        </Box>
      )}
    </Box>
  );

  if (!isLink) {
    return CardInner;
  }

  return (
    <Link href={href!}>
      <a target={target}>{CardInner}</a>
    </Link>
  );
};

export default Card;
