import { Flex } from '@chakra-ui/react';
import { LocationIcon } from '../../config/icons';

interface LocationProps {
  country?: string;
  region?: string;
  showIcon?: boolean;
}

export default function Location({
  country,
  region,
  showIcon = true,
}: LocationProps) {
  const str = [country, region].filter((i) => i).join(', ');

  return (
    <Flex color="gray.600" alignItems="center">
      {showIcon && <LocationIcon marginRight={2} />}
      {str}
    </Flex>
  );
}
